
import React from 'react';
import { Icon, useColorMode } from '@chakra-ui/react';

interface IDegenTokenLogo {
  size: number;
}

const DegenTokenLogo = ({size}: IDegenTokenLogo) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const color = colorMode === 'light' ? 'black' : 'white';
  return (
    <Icon fontSize={size} >
      <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.07707 235.503L63.3702 92.357C68.8063 79.2331 79.2331 68.8063 92.357 63.3702L235.503 4.07707C248.627 -1.35901 263.373 -1.35901 276.497 4.07707L419.643 63.3702C432.767 68.8063 443.194 79.2331 448.63 92.357L507.923 235.503C513.359 248.627 513.359 263.373 507.923 276.497L448.63 419.643C443.194 432.767 432.767 443.194 419.643 448.63L276.497 507.923C263.373 513.359 248.627 513.359 235.503 507.923L92.357 448.63C79.2331 443.194 68.8063 432.767 63.3702 419.643L4.07708 276.497C-1.359 263.373 -1.359 248.627 4.07707 235.503ZM34.8371 248.244C32.7802 253.21 32.7802 258.79 34.8371 263.756L94.1302 406.902C96.1871 411.868 100.132 415.813 105.098 417.87L248.244 477.163C253.21 479.22 258.79 479.22 263.756 477.163L406.902 417.87C411.868 415.813 415.813 411.868 417.87 406.902L477.163 263.755C479.22 258.79 479.22 253.21 477.163 248.244L417.87 105.098C415.813 100.132 411.868 96.1871 406.902 94.1302L263.756 34.8371C258.79 32.7802 253.21 32.7802 248.244 34.8371L105.098 94.1302C100.132 96.1871 96.1871 100.132 94.1302 105.098L34.8371 248.244Z" fill={color}/>
        <path d="M247.184 360.613H263.365C317.845 360.613 345.085 333.373 345.085 278.894V233.494C345.085 179.014 317.845 151.774 263.365 151.774H247.184V185.067H265.483C293.934 185.067 308.159 199.292 308.159 227.743V284.644C308.159 313.095 293.934 327.32 265.483 327.32H247.184V360.613Z" fill={color}/>
        <rect x="179.278" y="206.099" width="36.8632" height="34.923" fill={color}/>
        <rect x="179.278" y="274.004" width="36.8632" height="34.923" fill={color}/>
      </svg>
    </Icon>
  )
}

export default DegenTokenLogo
